import React from 'react'
import algoliasearch from 'algoliasearch'
import { InstantSearch, useNumericMenu, usePagination, useRefinementList, useSortBy } from 'react-instantsearch'

type Props = {
  children: React.ReactNode
  indexName: string
  initialUiState?: React.ComponentProps<typeof InstantSearch>['initialUiState'][number]
}

const envPrefix = process.env.NEXT_PUBLIC_ALGOLIA_ENV_PREFIX
const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)

const AlgoliaWrapper = ({ children, indexName, initialUiState }: Props) => {
  const indexValue = `${envPrefix}_${indexName}`.replace(`${envPrefix}_${envPrefix}`, `${envPrefix}`)
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexValue}
      initialUiState={{ [indexValue]: initialUiState }}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      {initialUiState && <DummyHooks initialUiState={initialUiState} />}
      {children}
    </InstantSearch>
  )
}

export default AlgoliaWrapper

type DummyHooksProps = { initialUiState: React.ComponentProps<typeof InstantSearch>['initialUiState'][number] }

/**
 * Dummy hooks are needed to make initialUiState work as only properties related to hooks that are immediately rendered will be used
 */
const DummyHooks = (props: DummyHooksProps) => (
  <>
    <DummyPagination />
    <DummySortBy sortBy={props.initialUiState.sortBy} />
    {Object.keys(props.initialUiState?.refinementList ?? {}).map((attribute) => (
      <DummyRefinementList key={attribute} attribute={attribute} />
    ))}
    {Object.entries(props.initialUiState?.numericMenu ?? {}).map(([attribute, value]) => (
      <DummyNumericList key={attribute} attribute={attribute} value={value} />
    ))}
  </>
)

export const DummyNumericList = ({ attribute, value }: { attribute: string; value: string }) => {
  useNumericMenu({
    attribute,
    items: [{ label: '', start: parseInt(value.split(':')[0]), end: parseInt(value.split(':')[1]) }],
  })
  return null
}

export const DummyRefinementList = ({ attribute }: { attribute: string }) => {
  useRefinementList({ attribute })
  return null
}

export const DummySortBy = ({ sortBy }: { sortBy: string }) => {
  useSortBy({
    items: [
      {
        value: sortBy,
        label: '',
      },
    ],
  })
  return null
}

export const DummyPagination = () => {
  usePagination({})
  return null
}

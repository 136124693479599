import { useLoaded } from '@interflora/ui-components/build/components/MainContent/useComponentLoading'
import ProductGalleryUI, {
  Props as UIProps,
} from '@interflora/ui-components/build/components/ProductGallery/ProductGallery'
import AnalyticsContext from 'context/AnalyticsContext'
import React, { useContext, useEffect, useRef } from 'react'

type Props = UIProps & {
  uiType?: string
  meta?: {
    deliveryId: string
  }
}

const ProductGallery = (props: Props) => {
  const analytics = useContext(AnalyticsContext)
  const {
    meta: { deliveryId },
    uiType,
    products,
  } = props
  const keysRef = useRef<string>()

  useEffect(() => {
    const keys = products.map((product) => product.key).join(',')
    if (keys !== keysRef.current) {
      keysRef.current = keys
      analytics.viewProductList({ id: deliveryId, name: uiType, products })
    }
  }, [analytics, uiType, deliveryId, products])

  useLoaded(props)

  return <ProductGalleryUI {...props} />
}

export default ProductGallery
